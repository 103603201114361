<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="4"
      >
        <h4 style="color: #b4aa99">DRE</h4>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
        <el-select
          filterable
          clearable
          class="select-info mx-1"
          placeholder="Data"
          :popper-append-to-body="true"
          size="small"
          v-model="filtro.data"
        >
          <el-option
            v-for="data in tipoDatas"
            class="select-info"
            :value="data.value"
            :label="data.nome"
            :key="data.id"
          ></el-option>
        </el-select>
        <el-date-picker
          v-model="filtro.ano"
          type="year"
          size="small"
          color="black"
          placeholder="Ano"
        ></el-date-picker>
        <el-button
          size="small"
          type="primary"
          :disabled="blockButton"
          class="mx-1"
          plain
          @click="getDRE()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div
      id="loadingTable"
      class="cardTable mb-1 vs-con-loading__container scroll-dre-table"
    >
      <b-table-simple
        responsive
        style="overflow: auto"
        sticky-header="72vh"
        striped
      >
        <b-thead>
          <b-tr>
            <b-th style="width: 4%">Plano</b-th>
            <b-th style="width: 8%">Anual</b-th>
            <b-th style="width: 8%">Jan</b-th>
            <b-th style="width: 8%">Fev</b-th>
            <b-th style="width: 8%">Mar</b-th>
            <b-th style="width: 8%">Abr</b-th>
            <b-th style="width: 8%">Mai</b-th>
            <b-th style="width: 8%">Jun</b-th>
            <b-th style="width: 8%">Jul</b-th>
            <b-th style="width: 8%">Aug</b-th>
            <b-th style="width: 8%">Sep</b-th>
            <b-th style="width: 8%">Out</b-th>
            <b-th style="width: 8%">Nov</b-th>
            <b-th style="width: 8%">Dez</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            :key="index"
            v-for="(item, index) in dre"
            :style="'color: black'"
          >
            <b-td
              :data="item.nome"
              :style="
                'font-size:' +
                (15 -
                  parseInt(
                    item.codigo &&
                      item.codigo != null &&
                      item.codigo != undefined
                      ? item.codigo.length > 7
                        ? 7
                        : item.codigo.length
                      : 3
                  ) /
                    2) +
                'px'
              "
            >
              <b>{{ item.codigo }} - </b>
              {{ item.nome }}
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.anual > item.meta_anual
                  ? 'tdMoneySuccess'
                  : item.anual == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="0"
                  :meta="item.meta_anual"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.anual"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="0"
                  :meta="item.meta_anual"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.anual"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.jan > item.meta_janeiro
                  ? 'tdMoneySuccess'
                  : item.jan == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="1"
                  :meta="item.meta_janeiro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jan"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="1"
                  :meta="item.meta_janeiro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jan"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.fev > item.meta_fevereiro
                  ? 'tdMoneySuccess'
                  : item.fev == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="2"
                  :meta="item.meta_fevereiro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.fev"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="2"
                  :meta="item.meta_fevereiro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.fev"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.mar > item.meta_marco
                  ? 'tdMoneySuccess'
                  : item.mar == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="3"
                  :meta="item.meta_marco"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.mar"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="3"
                  :meta="item.meta_marco"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.mar"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.abr > item.meta_abril
                  ? 'tdMoneySuccess'
                  : item.abr == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="4"
                  :meta="item.meta_abril"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.abr"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="4"
                  :meta="item.meta_abril"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.abr"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.mai > item.meta_maio
                  ? 'tdMoneySuccess'
                  : item.mai == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="5"
                  :meta="item.meta_maio"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.mai"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="5"
                  :meta="item.meta_maio"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.mai"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.jun > item.meta_junho
                  ? 'tdMoneySuccess'
                  : item.jun == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="6"
                  :meta="item.meta_junho"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jun"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="6"
                  :meta="item.meta_junho"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jun"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.jul > item.meta_julho
                  ? 'tdMoneySuccess'
                  : item.jul == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="7"
                  :meta="item.meta_julho"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jul"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="7"
                  :meta="item.meta_julho"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.jul"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.aug > item.meta_agosto
                  ? 'tdMoneySuccess'
                  : item.aug == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="8"
                  :meta="item.meta_agosto"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.aug"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="8"
                  :meta="item.meta_agosto"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.aug"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.sep > item.meta_setembro
                  ? 'tdMoneySuccess'
                  : item.sep == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="9"
                  :meta="item.meta_setembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.sep"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="9"
                  :meta="item.meta_setembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.sep"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.oct > item.meta_outubro
                  ? 'tdMoneySuccess'
                  : item.oct == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="10"
                  :meta="item.meta_outubro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.oct"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="10"
                  :meta="item.meta_outubro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.oct"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.nov > item.meta_novembro
                  ? 'tdMoneySuccess'
                  : item.nov == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="11"
                  :meta="item.meta_novembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.nov"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="11"
                  :meta="item.meta_novembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.nov"
                ></sidebar>
              </div>
            </b-td>
            <b-td
              class="font-bold"
              :class="
                item.dez > item.meta_dezembro
                  ? 'tdMoneySuccess'
                  : item.dez == null
                  ? 'tdMoneyGrey'
                  : 'tdMoneyDanger'
              "
            >
              <div class="w-full" v-if="filtroMovimento">
                <sidebarMovimento
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="12"
                  :meta="item.meta_dezembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.dez"
                />
              </div>
              <div class="w-full" v-else>
                <sidebar
                  :tipo_plano_conta="item.tipo_plano_conta"
                  :tipoData="filtro.data"
                  :ano="filtro.ano"
                  mes="12"
                  :meta="item.meta_dezembro"
                  :idPlanoConta="item.id"
                  :planoConta="item.nome"
                  :valor="item.dez"
                ></sidebar>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="w-full mb-2">
      <vx-card no-shadow card-background="dark">
        <vs-row
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <vs-button
              @click="voltar()"
              icon-pack="feather"
              line-origin="left"
              color="#fff"
              type="line"
              icon="icon-arrow-left"
              size="small"
            >
              <span style="font-size: 12px">Voltar</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="success"
              type="line"
              icon="ballot"
              :disabled="blockButton"
              @click="verTotais = !verTotais"
              size="small"
            >
              <span style="font-size: 14px; font-weight: 600"
                >Totalizadores</span
              >
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="primary"
              :disabled="blockButton"
              @click="verLucros = !verLucros"
              type="line"
              icon="attach_money"
              size="small"
            >
              <span style="font-size: 14px; font-weight: 600">Lucros</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <div class="w-full mb-2" v-show="verTotais">
      <vx-card no-shadow card-background="dark">
        <vs-row
          vs-type="flex"
          class
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-w="2.4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="success"
              @click="openModalTotalizador('ENTRADA', totais[0])"
              type="line"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Entrada: " + $currency(totais[0])
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2.4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="danger"
              @click="openModalTotalizador('SAIDA', totais[1])"
              type="line"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Saída: " + $currency(totais[1])
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2.4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="white"
              type="line"
              @click="openModalTotalizador('FRANCHISE', totais[2])"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Franchise: " + $currency(totais[2])
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2.4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="white"
              type="line"
              @click="openModalTotalizador('RDS', totais[3])"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "RDS: " + $currency(totais[3])
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2.4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="white"
              @click="openModalTotalizador('INVESTIMENTOS', totais[4])"
              type="line"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Investimentos: " + $currency(totais[4])
              }}</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <div class="w-full mb-2" v-show="verLucros">
      <vx-card no-shadow card-background="dark">
        <vs-row
          vs-type="flex"
          class
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-w="2.2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="primary"
              @click="openModalLucro('LUCRO', lucros.total)"
              type="line"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Lucro: " +
                $currency(lucros.total) +
                " (" +
                porcentagensLucro[0].toFixed(2) +
                "%)"
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="2.7"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="primary"
              @click="openModalLucro('LUCRO - FRANCHISE', lucros.franquia)"
              type="line"
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Lucro - Franchise: " +
                $currency(lucros.franquia) +
                " (" +
                porcentagensLucro[1].toFixed(2) +
                "%)"
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="3.3"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="primary"
              type="line"
              @click="
                openModalLucro(
                  'LUCRO - FRANCHISE - INVESTIMENTO',
                  lucros.franquiaInvestimento
                )
              "
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Lucro - Franchise - Investimento: " +
                $currency(lucros.franquiaInvestimento) +
                " (" +
                porcentagensLucro[2].toFixed(2) +
                "%)"
              }}</span>
            </vs-button>
          </vs-col>
          <vs-col
            vs-w="3.8"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-button
              line-origin="left"
              color="primary"
              type="line"
              @click="
                openModalLucro(
                  'LUCRO - FRANCHISE - INVESTIMENTO - RDS',
                  lucros.franquiaInvestimentoRDS
                )
              "
              size="small"
            >
              <span style="font-size: 12px; font-weight: 600">{{
                "Lucro - Franchise - Investimento - RDS: " +
                $currency(lucros.franquiaInvestimentoRDS) +
                " (" +
                porcentagensLucro[3].toFixed(2) +
                "%)"
              }}</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <component
      :selectedField="selectedLucro"
      :filtro="filtro"
      v-bind:is="modalLucro"
      :key="reRenderModalLucro"
      :totaisLucros="totaisLucros"
    ></component>

    <component
      :selectedField="selectedTotalizador"
      :filtro="filtro"
      :totais="totais"
      v-bind:is="modalTotalizador"
      :key="reRenderModalTotaliador"
      :totaisMensais="totaisMensais"
    ></component>
  </div>
</template>
<script>
import Plano from "./../../planoConta/plano"
import sidebarMovimento from "./components/DREMovimentosSideBar"
import sidebar from "./components/DREsideBar"
import lucroSideBar from "./components/lucroSideBar"
import totalizadorSideBar from "./components/totalizadorSideBar"
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      settings: {
        maxScrollbarLength: 2000,
        wheelSpeed: 1.5,
        height: 1000
      },
      tipoDatas: [
        { id: 1, nome: "Emissao", value: "data_emissao" },
        { id: 2, nome: "Vencimento", value: "data_vencimento" },
        { id: 3, nome: "Pagamento", value: "data_pagamento" }
      ],
      ValorAnualByPlanoConta: 0,
      filtro: { ano: new Date(), data: 'data_pagamento' },
      dre: [],
      verTotais: false,
      verLucros: false,
      totais: [0, 0, 0, 0, 0],
      lucros: { total: 0, franquia: 0, franquiaInvestimento: 0, franquiaInvestimentoRDS: 0 },
      modalLucro: '',
      reRenderModalLucro: 0,
      modalTotalizador: 0,
      reRenderModalTotaliador: 0,
      selectedLucro: { label: '', valor: 0 },
      selectedTotalizador: { label: '', valor: 0 },
      totaisMensais: [],
      totaisLucros: [],
      porcentagensLucro: [0, 0, 0, 0],
      blockButton: false,
      filtroMovimento: false
    }
  },
  methods: {
    async getDRE () {
      await this.$vs.loading({ container: "#loadingTable", scale: 0.6 })
      this.verLucros = false
      this.verTotais = false
      this.blockButton = true
      let ano
      if (this.filtro.ano == null) {
        const newDate = new Date()
        ano = newDate.getUTCFullYear()
      } else {
        ano = this.filtro.ano.getUTCFullYear()
      }
      try {
        if (this.filtro.data == "data_pagamento") {
          this.filtroMovimento = true
        } else {
          this.filtroMovimento = false
        }
        this.dre = await this.$http.post(`getDRE`, {
          ano: ano,
          tipoData: this.filtro.data
        })
        let i = -1
        for (const item of this.dre) {
          if (item.id_parent == 0) {
            i = i + 1
            console.log(i == 2 ? item : "")
            this.totais[i] = item.anual ? item.anual : 0
            this.totaisMensais[i] = item
          }
        }
        this.lucros.total = this.totais[0] - this.totais[1]
        this.lucros.franquia = this.lucros.total - this.totais[2]
        this.lucros.franquiaInvestimento = this.lucros.franquia - this.totais[4]
        this.lucros.franquiaInvestimentoRDS = this.lucros.franquiaInvestimento - this.totais[3]

        const divider = this.totais[0] == 0 ? 100 : this.totais[0]

        this.porcentagensLucro[0] = (parseFloat(this.lucros.total) / divider * 100)
        this.porcentagensLucro[0] = isNaN(this.porcentagensLucro[0]) ? 0 : this.porcentagensLucro[0]
        this.porcentagensLucro[1] = (parseFloat(this.lucros.franquia) / divider * 100)
        this.porcentagensLucro[1] = isNaN(this.porcentagensLucro[1]) ? 0 : this.porcentagensLucro[1]
        this.porcentagensLucro[2] = (parseFloat(this.lucros.franquiaInvestimento) / divider * 100)
        this.porcentagensLucro[2] = isNaN(this.porcentagensLucro[2]) ? 0 : this.porcentagensLucro[2]
        this.porcentagensLucro[3] = (parseFloat(this.lucros.franquiaInvestimentoRDS) / divider * 100)
        this.porcentagensLucro[3] = isNaN(this.porcentagensLucro[3]) ? 0 : this.porcentagensLucro[3]

        await this.getTotalizadorLucros(0, this.totaisMensais[0], this.totaisMensais[1])
        await this.getTotalizadorLucros(1, this.totaisLucros[0], this.totaisMensais[2])
        await this.getTotalizadorLucros(2, this.totaisLucros[1], this.totaisMensais[4])
        await this.getTotalizadorLucros(3, this.totaisLucros[2], this.totaisMensais[3])

      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockButton = false
        await this.$vs.loading.close("#loadingTable > .con-vs-loading")
      }
    },
    async getTotalizadorLucros (x, y, z) {
      this.totaisLucros[x] = {
        anual: (y.anual ? y.anual : 0) - (z.anual ? z.anual : 0),
        jan: (y.jan ? y.jan : 0) - (z.jan ? z.jan : 0),
        fev: (y.fev ? y.fev : 0) - (z.fev ? z.fev : 0),
        mar: (y.mar ? y.mar : 0) - (z.mar ? z.mar : 0),
        abr: (y.abr ? y.abr : 0) - (z.abr ? z.abr : 0),
        mai: (y.mai ? y.mai : 0) - (z.mai ? z.mai : 0),
        jun: (y.jun ? y.jun : 0) - (z.jun ? z.jun : 0),
        jul: (y.jul ? y.jul : 0) - (z.jul ? z.jul : 0),
        aug: (y.aug ? y.aug : 0) - (z.aug ? z.aug : 0),
        sep: (y.sep ? y.sep : 0) - (z.sep ? z.sep : 0),
        oct: (y.oct ? y.oct : 0) - (z.oct ? z.oct : 0),
        nov: (y.nov ? y.nov : 0) - (z.nov ? z.nov : 0),
        dez: (y.dez ? y.dez : 0) - (z.dez ? z.dez : 0),
      }
    },
    async openModalLucro (label, valor) {
      this.selectedLucro = {}
      this.selectedLucro.label = label
      this.selectedLucro.valor = valor
      this.modalLucro = 'lucroSideBar'
      this.reRenderModalLucro += 1
    },
    async openModalTotalizador (label, valor) {
      this.selectedTotalizador = {}
      this.selectedTotalizador.label = label
      this.selectedTotalizador.valor = valor
      this.modalTotalizador = 'totalizadorSideBar'
      this.reRenderModalTotaliador += 1
    },

    async getValorAnualByPlanoConta () {
      this.ValorAnualByPlanoConta = 123
    },
    async voltar () {
      this.$router.push("/financeiro")
    }
  },
  async mounted () {
    await this.getDRE()
  },
  components: {
    ...components,
    Plano,
    sidebarMovimento,
    sidebar,
    totalizadorSideBar, lucroSideBar
  }
};
</script>
<style lang="scss">
.scroll-dre-table {
  height: 75vh !important;
  width: 100% !important;
  color: #002638;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
.tdMoney {
  font-size: 13px;
  &:hover {
    color: #006ea1;
  }
}
.tdMoneySuccess {
  font-size: 13px;
  color: #00bba2;
  &:hover {
    color: rgb(18, 139, 109);
  }
}
.tdMoneyDanger {
  font-size: 13px;
  color: #c0564b;
  &:hover {
    color: rgb(187, 36, 36);
  }
}
.tdMoneyGrey {
  font-size: 13px;
  color: #85858562;
  &:hover {
    color: #006ea1;
  }
}
</style>
