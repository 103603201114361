<template>
  <div>
    <div
      :class="
        valor > 0
          ? tipo_plano_conta == 1
            ? 'colorGreen'
            : tipo_plano_conta == 2
            ? 'colorRed'
            : 'colorBlue'
          : 'colorGray'
      "
      style="cursor: pointer"
      @click="openModal()"
    >
      {{ $currency(valor) }}
    </div>
    <vside
      @hide="cancelar()"
      :active="modalDRESideBar"
      :item="{}"
      size="huger"
      v-if="open"
      textSucces
    >
      <div slot="body">
        <div id="contasDRE" class="cardTable vs-con-loading__container">
          <div
            class="mt-1"
            v-if="contas.length == 0 || contas.length == undefined"
          >
            <div class="pt-20 scroll-contas-dre con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhuma Conta a Receber
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="w-full">
            <div>
              <div class>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">{{ planoConta }} :</span>
                    <span style="font-size: 18px">
                      <b
                        :style="
                          valor > meta ? 'color: #00BBA2' : 'color: #C0564B'
                        "
                        >{{ $currency(valor) }}</b
                      >
                    </span>
                    De
                    <span style="font-size: 18px; color: #ffca00">
                      <b>{{ $currency(meta) }}</b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <VuePerfectScrollbar class="scroll-contas-dre" :settings="settings">
              <vs-table
                class="px-2"
                :data="contas"
                stripe
                pagination
                max-items="9"
                id="tableContasReceber"
              >
                <template slot="thead">
                  <vs-th sort-key="nota" style="width: 1%" id="center"
                    >Nota</vs-th
                  >
                  <vs-th sort-key="emissao" style="width: 10%" id="center"
                    >Emissão</vs-th
                  >
                  <vs-th sort-key="vencimento" style="width: 10%" id="center"
                    >Vencimento</vs-th
                  >
                  <vs-th sort-key="quitacao" style="width: 10%" id="center"
                    >Quitação</vs-th
                  >
                  <vs-th style="width: 10%">Cliente</vs-th>
                  <vs-th style="width: 2%" id="center">Contrato</vs-th>
                  <vs-th sort-key="valor" style="width: 10%" id="center"
                    >Valor</vs-th
                  >
                  <vs-th style="width: 10%" id="center">Juros</vs-th>
                  <vs-th sort-key="pago" style="width: 12%" id="center"
                    >V. Pago</vs-th
                  >
                  <vs-th style="width: 12%" id="center">Plano Conta</vs-th>
                  <vs-th style="width: 12%" id="center">Observação</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :style="
                      conta.id_status == 4
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : conta.id_status == 3
                        ? 'color:#00BBA2;background-color: #dff2f0 !important'
                        : ''
                    "
                    :key="index"
                    v-for="(conta, index) in data"
                  >
                    <vs-td :data="conta.nota" class="text-center">
                      <b>{{ conta.nota }}</b>
                    </vs-td>
                    <vs-td
                      :data="conta.data_emissao"
                      class="text-center"
                      style="font-size: 12px"
                    >
                      <b>{{ conta.data_emissao | moment("DD/MM/YYYY") }}</b>
                    </vs-td>
                    <vs-td
                      :data="conta.data_vencimento"
                      class="text-center"
                      style="font-size: 12px"
                    >
                      <b>{{ conta.data_vencimento | moment("DD/MM/YYYY") }}</b>
                    </vs-td>
                    <vs-td
                      :data="conta.data_pagamento"
                      class="text-center"
                      style="font-size: 12px"
                    >
                      <b>
                        {{
                          conta.data_pagamento ? conta.data_pagamento : "..."
                        }}
                      </b>
                    </vs-td>
                    <vs-td>{{ conta.cliente }}</vs-td>
                    <vs-td v-if="conta.id_contrato" class="text-center">
                      <a
                        target="_blank"
                        :href="'contrato?id=' + conta.id_contrato"
                        style="color: darkblue; cursor: pointer"
                      >
                        <b>{{ conta.id_contrato }}</b>
                      </a>
                    </vs-td>
                    <vs-td v-else class="text-center">...</vs-td>
                    <vs-td
                      class="text-center"
                      :data="conta.valor"
                      style="font-size: 12px"
                    >
                      <b>{{ $currency(conta.valor) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.juros) }}</b>
                    </vs-td>
                    <vs-td
                      class="text-center"
                      :data="conta.valor_pago"
                      style="font-size: 12px"
                    >
                      <b>{{ $currency(conta.valor_pago) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b>
                        {{
                          conta.plano_contas ? conta.plano_contas : "Sem dados"
                        }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b>
                        {{ conta.obs ? conta.obs : "Sem dados" }}
                      </b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../../../cliente/visualizar.vue";
export default {
  props: {
    valor: null,
    planoConta: null,
    idPlanoConta: null,
    tipoData: null,
    ano: null,
    mes: null,
    meta: null,
    tipo_plano_conta: null,
  },
  data() {
    return {
      modalDRESideBar: false,
      open: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 9.5,
        height: 1000,
      },
      filtro: {},
      contas: {},
      totalRecebido: 0,
      total: 0,
      buttonDisabled: false,
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modalDRESideBar = await true;
      await this.getContasReceber();
    },
    async getContasReceber() {
      await this.$vs.loading({ container: "#contasDRE", scale: 0.6 });
      let ano;
      if (this.ano == null) {
        const newDate = new Date();
        ano = newDate.getUTCFullYear();
      } else {
        ano = this.ano.getUTCFullYear();
      }
      this.filtro.id_plano_conta = this.idPlanoConta;
      this.filtro.mes = this.mes;
      this.filtro.tipoData = this.tipoData;
      this.filtro.ano = ano;
      try {
        this.contas = await this.$http.post(`getContasDRE`, this.filtro);
        this.total = this.contas.reduce(function (prev, cur) {
          return prev + cur.valor;
        }, 0);
        this.totalRecebido = this.contas.reduce(function (prev, cur) {
          return prev + cur.valor_pago;
        }, 0);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#contasDRE > .con-vs-loading");
    },
    async exportToExcel() {
      try {
        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "Nota",
            "Data Emissão",
            "Data Vencimento",
            "Data Pagamento",
            "Clinte",
            "Contrato",
            "Valor",
            "Juros",
            "Valor Pago",
            "Plano Conta",
          ];
          let headerVal = [
            "nota",
            "data_emissao",
            "data_vencimento",
            "data_pagamento",
            "clinte",
            "id_contrato",
            "valor",
            "juros",
            "valor_pago",
            "plano_conta",
          ];
          const list = this.contas;
          const data = await this.formatJson(headerVal, list);
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "Contas a Receber",
            autoWidth: true,
            bookType: "xlsx",
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async voltar() {
      this.$router.push("/financeiro");
    },
    async Filtrar(filtro) {
      this.filtro = await filtro;
      await this.getContasReceber();
    },
    async deletar(conta) {
      this.buttonDisabled = await true;
      try {
        await this.$http.put(`conta/` + conta.id, { id_status: 5 });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da Conta a Receber N°" + conta.id,
        };
        await this.$logger(logData);
        await this.getContasReceber();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.buttonDisabled = await false;
      }
    },
    cancelar() {
      this.modalDRESideBar = false;
    },
    async quitacaoDelet(conta) {
      this.buttonDisabled = await true;
      try {
        await this.$http.put(`conta/` + conta.id, {
          id_status: 1,
          data_pagamento: null,
        });
        await this.$http.delete(`desquitar/` + conta.id);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da Quitação da Conta a Pagar N°" + conta.id,
        };
        await this.$logger(logData);
        await this.getContasReceber();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.buttonDisabled = await false;
      }
    },
    async contrato(id_contrato) {
      this.$router.push("/contrato?id=" + id_contrato);
    },
  },
  async mounted() {
    //await this.getContasReceber();
  },
  components: {
    ...components,
    VisualizarCliente,
  },
};
</script>
<style lang="scss">
.scroll-contas-dre {
  height: 85vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
.colorGreen {
  color: #00bba2 !important;
}
.colorRed {
  color: #dd3522 !important;
}
.colorBlue {
  color: #006abb !important;
}
.colorGray {
  color: #bec8c7;
}
</style>
