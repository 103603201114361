<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalLucro"
      :item="{}"
      size="huger"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div id="lucroTable" class="cardTable mb-1 vs-con-loading__container">
          <div>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase"
                        >{{ selectedField.label }}:
                      </b>
                    </span>
                    <span class="mr-2" style="font-size: 22px">
                      <b style="color: #ffca00">
                        {{ $currency(selectedField.valor) }}
                      </b>
                    </span>
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase">/ Mês Atual: </b>
                    </span>
                    <span class="mr-2" style="font-size: 22px">
                      <b style="color: #ffca00">
                        {{ $currency(totalMensal) }}
                      </b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <VuePerfectScrollbar class="scroll-lucros" :settings="settings">
              <vs-table :data="lucroData" stripe :key="reRender">
                <template slot="thead">
                  <vs-th style="width: 4%" id="center">Anual</vs-th>
                  <vs-th style="width: 4%" id="center">Jan</vs-th>
                  <vs-th style="width: 4%" id="center">Fev</vs-th>
                  <vs-th style="width: 4%" id="center">Mar</vs-th>
                  <vs-th style="width: 4%" id="center">Abr</vs-th>
                  <vs-th style="width: 4%" id="center">Mai</vs-th>
                  <vs-th style="width: 4%" id="center">Jun</vs-th>
                  <vs-th style="width: 4%" id="center">Jul</vs-th>
                  <vs-th style="width: 4%" id="center">Aug</vs-th>
                  <vs-th style="width: 4%" id="center">Sep</vs-th>
                  <vs-th style="width: 4%" id="center">Out</vs-th>
                  <vs-th style="width: 4%" id="center">Nov</vs-th>
                  <vs-th style="width: 4%" id="center">Dez</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.anual) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.jan) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.fev) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.mar) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.abr) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.mai) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.jun) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.jul) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.aug) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.sep) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.oct) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.nov) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b> {{ $currency(data.dez) }}</b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from '@/components/default/exports.js'
export default {
  props: {
    selectedField: {},
    filtro: null,
    totaisLucros: null
  },
  data () {
    return {
      lucroData: {},
      modalLucro: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000,
      },
      totalMensal: 0,
      meses: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dez'],
      reRender: 0
    }
  },
  methods: {
    async fechar () {
      this.modalLucro = false
      this.selectedField = {}
    },
    async getData () {
      await this.$vs.loading({ container: "#lucroTable", scale: 0.6 })
      try {
        switch (this.selectedField.label.toUpperCase().trim()) {
          case "LUCRO":
            this.lucroData = this.totaisLucros[0]
            break
          case "LUCRO - FRANCHISE":
            this.lucroData = this.totaisLucros[1]
            break
          case "LUCRO - FRANCHISE - INVESTIMENTO":
            this.lucroData = this.totaisLucros[2]
            break
          case "LUCRO - FRANCHISE - INVESTIMENTO - RDS":
            this.lucroData = this.totaisLucros[3]
            break
          default:
            return []
        }
        const mesAtual = this.meses[`${new Date().getMonth()}`]
        this.totalMensal = this.lucroData[mesAtual]
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.reRender += 1
        await this.$vs.loading.close("#lucroTable > .con-vs-loading")
      }
    },
  },
  async mounted () {
    this.modalLucro = true
    await this.getData()
  },
  components: {
    ...components,
  },
}
</script>
<style lang="scss">
.scroll-lucros {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
