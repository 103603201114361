<template>
  <div>
    <div
      :class="
        valor > 0
          ? tipo_plano_conta == 1
            ? 'colorGreen'
            : tipo_plano_conta == 2
            ? 'colorRed'
            : 'colorBlue'
          : 'colorGray'
      "
      style="cursor: pointer"
      @click="openModal()"
    >
      {{ $currency(valor) }}
    </div>
    <vside
      @hide="cancelar()"
      :active="modalMovimentoDRESideBar"
      :item="{}"
      size="huger"
      v-if="open"
      textSucces
    >
      <div slot="body">
        <div id="movimentosDRE" class="cardTable vs-con-loading__container">
          <div
            class="mt-1"
            v-if="contas.length == 0 || contas.length == undefined"
          >
            <div class="pt-20 scroll-contas-dre con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhuma Movimento
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="w-full">
            <div>
              <div class>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">{{ planoConta }} :</span>
                    <span style="font-size: 18px">
                      <b
                        :style="
                          valor > meta ? 'color: #00BBA2' : 'color: #C0564B'
                        "
                        >{{ $currency(valor) }}</b
                      >
                    </span>
                    De
                    <span style="font-size: 18px; color: #ffca00">
                      <b>{{ $currency(meta) }}</b>
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <VuePerfectScrollbar class="scroll-contas-dre" :settings="settings">
              <vs-table
                class="px-2"
                :data="contas"
                stripe
                pagination
                max-items="9"
                id="tableContasReceber"
              >
                <template slot="thead">
                  <vs-th sort-key="nota" style="width: 1%" id="center"
                    >Conta</vs-th
                  >
                  <vs-th style="width: 10%">Empresa</vs-th>
                  <vs-th style="width: 10%">Cliente/Fornecedor</vs-th>
                  <vs-th style="width: 10%">Portador</vs-th>
                  <vs-th sort-key="vencimento" style="width: 10%" id="center"
                    >Juros</vs-th
                  >
                  <vs-th sort-key="quitacao" style="width: 10%" id="center"
                    >V. Pago</vs-th
                  >
                  <vs-th style="width: 12%" id="center">Data</vs-th>
                  <vs-th style="width: 12%" id="center">Plano Conta</vs-th>
                  <vs-th style="width: 12%" id="center">Observação</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :style="
                      conta.tipo_movimento == 1
                        ? 'color:red ;background-color: #fce3e3 !important'
                        : conta.tipo_movimento == 2
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : ''
                    "
                    :key="index"
                    v-for="(conta, index) in data"
                  >
                    <vs-td class="text-center">
                      <b>{{
                        conta.nota > 0
                          ? conta.nota +
                            (conta.parcela ? "." + conta.parcela : "")
                          : "..."
                      }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ conta.empresa }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{
                        conta.cliente
                          ? conta.cliente
                          : conta.fornecedor
                          ? conta.fornecedor
                          : "..."
                      }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ conta.portador ? conta.portador : "..." }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.juros) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.valor) }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{
                        conta.data_movimento ? conta.data_movimento : "..."
                      }}</b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b>
                        {{
                          conta.plano_contas ? conta.plano_contas : "Sem dados"
                        }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 10px">
                      <b>
                        {{ conta.obs ? conta.obs : "Sem dados" }}
                      </b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../../../cliente/visualizar.vue";
export default {
  props: {
    valor: null,
    planoConta: null,
    idPlanoConta: null,
    tipoData: null,
    ano: null,
    mes: null,
    meta: null,
    tipo_plano_conta: null,
  },
  data() {
    return {
      modalMovimentoDRESideBar: false,
      open: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 9.5,
        height: 1000,
      },
      filtro: {},
      contas: {},
      buttonDisabled: false,
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modalMovimentoDRESideBar = await true;
      await this.getMovimentosDRE();
    },
    async getMovimentosDRE() {
      await this.$vs.loading({ container: "#movimentosDRE", scale: 0.6 });
      let ano;
      if (this.ano == null) {
        const newDate = new Date();
        ano = newDate.getUTCFullYear();
      } else {
        ano = this.ano.getUTCFullYear();
      }
      this.filtro.id_plano_conta = this.idPlanoConta;
      this.filtro.tipoData = this.tipoData;
      this.filtro.mes = this.mes;
      this.filtro.ano = ano;
      try {
        this.contas = await this.$http.post(`getMovimentosDRE`, this.filtro);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#movimentosDRE > .con-vs-loading");
    },
    cancelar() {
      this.modalMovimentoDRESideBar = false;
    },
  },
  components: {
    ...components,
    VisualizarCliente,
  },
};
</script>
<style lang="scss">
.scroll-contas-dre {
  height: 85vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
.colorGreen {
  color: #00bba2 !important;
}
.colorRed {
  color: #dd3522 !important;
}
.colorBlue {
  color: #006abb !important;
}
.colorGray {
  color: #bec8c7;
}
</style>
